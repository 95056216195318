// custom css


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// normalize CSS across browsers
import "./src/css/normalize.css";
import "./src/css/bootstrap-grid.min.css"
import "./src/css/global.css"
import "./src/css/font.css"

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (location.hash && location.hash.includes("#")) {
        return true;
    }
    window.history.scrollRestoration = 'manual';

    const currentPosition = getSavedScrollPosition(location, location.key);
    let timeout = 100;
    if (currentPosition && currentPosition.length > 1 && currentPosition[1]) {
        const y = currentPosition[1];
        if (y > (2 * window.innerHeight)) {
            timeout = 750;
        }
    }
    setTimeout(() => {
        window.scrollTo(...(currentPosition || [0, 0]));
    }, timeout);
    return false;
};